.services {
    &__slider {
        display: flex;
        align-items: center;
        flex-grow: 1;
        margin: -24px 0;
        @include phone {
            margin: 0 -16px;
        }

        .slick-list {
            width: 100%;
        }

        .slick-slide {
            height: 100%;

            &>div {
                margin: 24px 8px;

                @include phone {
                    margin: 8px;
                }
            }
        }
    }

    &__card-container {
        display: flex !important;
        height: 540px;
        width: 100%;
        border: none;
        padding: 0;
        outline: none;
        box-sizing: border-box;

        @include phone-tablet {
            height: 450px;
        }
        @include phone {
            height: 560px;
        }

        &--small {
            height: 235px;
            @include phone-tablet {
                height: 160px;
            }
            @include phone {
                height: 200px;
            }
            .service-card {
                text-align: center;
            }
        }

        &--large {
            height: 830px;

            @media (max-width: 880px) { 
                height: 600px;
            }
            @include phone-tablet {
                height: 680px;
            }
            @include phone {
                height: 890px;
            }
        }
    }
}

.service-card {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    background: $white;
    width: 100%;
    @include shadow-low;


    @include tablet {
        padding: 8px;
    }
    
    &__header {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $grey;
        padding-bottom: 8px;

        &--large {
            min-height: 81px;
            justify-content: center;
            @include small-tablet {
                min-height: 0;
            }
        }
    }

    &__title {
        text-align: center;
        text-transform: uppercase;
    }

    &__subtitle {
        text-align: center;
        text-transform: uppercase;
        margin-top: 8px;
        font-weight: 300;
    }

    &__price {
        text-align: center;
        font-size: 22px;
        font-weight: 300;
        color: $darkblue;
        @include tablet {
            padding: 8px;
        }
        span {
            font-family: 'Montserrat', sans-serif;
            font-size: 42px;
            @include tablet {
                font-size: 24px;
            }
        }
    }

    &__price-info {
        background: $primary;
        color: $white;
        padding: 8px 16px;
        margin-top: 16px;
        @include tablet {
            padding: 8px;
        }

    }

    &__info {
        padding: 8px 0 8px;
        font-weight: 400;
        @include tablet {
            padding: 8px;
        }
    }

    &__additional {
        font-weight: 300;
        line-height: 16px;
        @include tablet {
            padding: 8px;
        }
    }
}