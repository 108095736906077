.scroll-top-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 25px;
    left: 25px;
    background: $scroll-top-button-bg;
    width: 60px;
    height: 60px;
    transition: 0.2s;
    z-index: 1000;

    &:hover {
        background: $scroll-top-button-bg-hover;
    }

    &:active {
        width: 70px;
        height: 70px;
        bottom: 20px;
        left: 20px;
    }

    &__icon.material-icons {
        color: white;
        font-size: 28px;
        pointer-events: none;
    }
}