.table {
    box-sizing: border-box;
    width: 100%;
    overflow-x: auto;
    table {
        width: 100%;
    }
    thead {
        font-weight: 400;
        tr {
            background: $table-head-row-bg;
        }
    }
    tbody {
        font-weight: 300;
        tr {
            &:nth-child(even) {
              background: $table-body-row-even-bg;
            }
        }
    }
    th,td {
        border: 1px solid $table-border;
        padding: 8px 14px;
        text-align: left;
    }
}