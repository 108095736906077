.accounting {
    background-color: $accounting-bg;
    font-weight: 300;
    p {
        span {
            display: block;
            margin: 20px;
            font-weight: 400;
            text-align: center;
        }
    }
}