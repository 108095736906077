.logo {
    position: sticky;
    top: 0;
    z-index: 1101;
    margin-top: -80px !important;
    margin-bottom: 10px !important;
    pointer-events: none;

    &__icon {
        width: 64px;
        height: 64px;

        @include phone {
            width: 56px;
            height: 56px;
        }
    }
}