.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $footer-bg;
    height: 100px;
    color: $footer-text;
    &__copyright {
        text-align: center;
    }
}