.about-us {
    background-color: $about-us-bg;
    
    &__container {
        display: flex;

        .slick-list {
            width: 100%;
        }
    }

    &__slider {
        display: flex;
        align-items: center;
        width: calc(100% - 48px * 2);
        margin: -24px 0 24px 0;
        flex-grow: 1;
        @include phone {
            margin: 0 -16px;
        }

        .slick-slide {
            height: 100%;

            &>div {
                margin: 24px 0;
                @include phone {
                    margin: 8px;
                }
            }
        }
    }

    &__item {
        display: flex !important;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        width: 100%;
        border: none;
        outline: none;
        background: $darkblue;
        color: white;
        box-sizing: border-box;
        text-align: center;

        img {    
            width: 100%;
        }
    }
}