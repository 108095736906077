@mixin phone {
  @media (max-width: 599px) { @content; }
}
@mixin phone-tablet {
  @media (max-width: 700px) { @content; }
}
@mixin small-tablet {
  @media (max-width: 859px) { @content; }
}
@mixin tablet {
  @media (max-width: 1079px) { @content; }
}
@mixin laptop {
  @media (max-width: 1400px) { @content; }
}
