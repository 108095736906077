$white: white;
$black: black;
$lightgrey: #f1f1f1;
$grey: #bababa;
$darkgrey: grey;
$lightblue: #EAF2F8;
$blue: #2980B9;
$darkblue: #1A5276;
$orange: #F39C12;

$primary: $blue;
$secondary: $darkblue;
$lightbg: $lightblue;

$link-hover: $orange;

/** Header */
$header-bg: $secondary;
$header-text: $white;

/** Topbar */
$topbar-menu-title-border: $grey;
$topbar-menu-text: $white;
$topbar-menu-drawer-text: $black;

/** Footer */
$footer-bg: $primary;
$footer-text: $white;

/** Section */
$section-title: $black;
$section-subtitle: $black;
$section-stock: $primary;


/** Call Button */
$call-button-bg: $primary;
$call-button-border: $primary;

/** Sctoll Top Button */
$scroll-top-button-bg: rgba($primary, .2);
$scroll-top-button-bg-hover: rgba($primary, .6);

/** Table */
$table-border: rgba($primary, .4);
$table-head-row-bg: rgba($primary, .3);
$table-body-row-even-bg: rgba($primary, .1);

/* Section 1 */
$about-us-bg: $lightblue;

/* Section 2 */
$juridical-services-bg: $white;
$juridical-services-card-info-text: $black;
$juridical-services-card-bg: inherit;
$juridical-services-card-bg-hover: $lightblue;

/* Section 3 */
$history-text: $white;

/* Section 4 */
$registration-juridical-person-bg: $lightblue;

/* Section 5 */
$registration-individual-entrepreneur-bg: $lightgrey;

/* Section 6 */
$accounting-bg: $white;

/* Section 7 */
$liquidation-juridical-person-bg: $lightgrey;

/* Section 8 */
$sro-bg: $lightblue;

/* Section 9 */
$property-registration-bg: $white;

/* Section 10 */
$cadastral-services-bg: $lightgrey;

/* Section 10 */
$construction-interim-bg: $white;

/* Section 12 */
$contacts-bg: $lightblue;