.section {
    display: flex;
    flex-direction: column;
    padding: 32px 0;
    
    &__header {
        margin-bottom: 32px;
    }

    &__title {
        display: block;
        text-align: center;
        color: $section-title;
    }
    
    &__subtitle {
        text-align: center;
        color: $section-subtitle;
        margin-top: 8px !important;
        font-weight: 300;
    }

    &__stock {
        text-align: center;
        color: $section-stock;
        margin-top: 8px;
    }
}