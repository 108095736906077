body {
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: normal;

    h1, h2 {
        font-family: 'Montserrat', sans-serif;
    }

    h1 {
        font-size: 28px;
        font-weight: 500;
        @include phone-tablet {
            font-size: 24px;
        }
    }
    h2 {
        font-size: 36px;
        font-weight: 300;
        margin-bottom: 0;
        @include phone {
            font-size: 24px;
        }
    }
    h3 {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 0;
        @include tablet {
            font-size: 20px;
        }
    }
    h4 {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0;
        @include phone-tablet {
            font-size: 16px;
        }
    }

    h5 {
        font-weight: 300;
    }

    a {
        transition: .1s;
        &:hover, :active {
            color: $link-hover;
        }
    }

    ol, ul {
        padding: 0 16px;
        li {
            margin: 8px 0;
        }
    }

    p {
        margin: 8px 0;
    }

    b {
        font-weight: 600;
    }

    button {
        font-size: 16px;
    }

    .annotation {
        color: $darkblue;
    }


    .container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 32px;
        box-sizing: border-box;
        @include tablet {
            padding: 0 16px;
        }

        &--large {
            max-width: 1360px;
        }
    }
}
