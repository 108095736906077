.topbar {

    .MuiToolbar-gutters {
        padding: 0;
    }
    
    &__container {
        display: flex;
        align-items: center;
    }

    &__menu {
        display: flex;
        margin: 0 -16px;
        width: 100%;
        margin-left: calc(64px + 16px);
        @include tablet {
            flex-direction: column;
            padding: 16px 0;
            margin: 0;
        }
    }

    &__menu-title {
        display: flex;
        align-items: center;
        font-family: 'Montserrat', sans-serif;
        min-height: 64px;
        padding: 0 16px;
        border-bottom: 1px solid $topbar-menu-title-border;
        @include phone {
            min-height: 56px;
        }
    }

    &__menu-button {
        padding: 0 !important;
        margin-left: calc(64px + 16px) !important;
    }

    &__button {
        &.MuiButton-text {
            flex-grow: 1;
            padding: 4px 16px;
        }
        .MuiButton-label {
            a {
                color: $topbar-menu-text;
                &:hover {
                    color: $link-hover;
                }
            }
            
            @include tablet {
                a {
                    color: $topbar-menu-drawer-text;
                }
                justify-content: left;
                text-align: left;
                max-width: 300px;
                padding: 0;
            }
        }
    }
}