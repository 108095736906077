.juridical-services {
    background-color: $juridical-services-bg;
    
    &__content {
        display: flex;
        margin: 0 -8px;
        justify-content: center;
        @media (max-width: 1250px) { 
            flex-wrap: wrap;
        }
        @include phone {
            flex-direction: column;
            margin: -8px;
        }
    }

    &__card {
        flex-grow: 1;
        width: 100%;
        min-width: 224px;
        max-width: 300px;
        margin: 8px;
        @include phone {
            flex-direction: column;
            max-width: 100%;
            margin: 8px 0;
        }
    }
    
    &__info {
        padding-top: 16px !important;
        text-align: justify;
        font-weight: 300;
    }
}

.juridical-services-card {
    padding: 32px 16px;
    box-sizing: border-box;
    background-color: $juridical-services-card-bg;
    transition: .3s;

    @include shadow-low;

    &:hover {
        background-color: $juridical-services-card-bg-hover;
    }
    
    @include tablet {
        padding: 32px 8px;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
        .material-icons {
            font-size: 64px;
        }
        .icon {
            height: 70px;
        }
    }
    &__title {
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        cursor: pointer;
    }
    &__info {
        margin-top: 8px;
        text-align: center;
        color: $juridical-services-card-info-text;
        font-weight: 300;
        @include tablet {
            font-size: 14px;
        }
    }
}