.call-button {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 25px;
    right: 25px;
    background: $call-button-bg;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    transition: 0.2s;
    z-index: 1000;
    cursor: pointer;

    @include shadow-low;
    
    @include tablet {
        display: flex;
    }

    &:active {
        width: 70px;
        height: 70px;
        bottom: 20px;
        right: 20px;
    }

    &__icon.material-icons {
        color: white;
        font-size: 28px;
        pointer-events: none;
    }

    &::before, &::after {
        content: " ";
        display: block;
        position: absolute;
        border: 50%;
        border-radius: 50%;
        border: 1px solid $call-button-border;
        left: -20px;
        right: -20px;
        top: -20px;
        bottom: -20px;
        animation: animate 1.5s linear infinite;
    }
}

@keyframes animate {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}