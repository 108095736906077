.contacts {
    background: $contacts-bg;

    &__container {
        display: flex;
        @include phone-tablet {
            flex-direction: column-reverse;
        }
    }

    &__map {
        height: 400px;
        width: 100%;
        min-width: 400px;
        @include phone-tablet {
            min-width: auto;
        }
    }

    &__content {
        max-width: 400px;
        width: 100%;
        padding: 0 32px;
        @include phone-tablet {
            max-width: 100%;
            padding: 0 0 32px;
        }
    }

    &__title {
        margin-bottom: 32px !important;
    }
}