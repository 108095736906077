.header {
    display: flex;
    height: 100px;
    padding: 16px 0;
    box-sizing: border-box;
    background: $header-bg;
    color: $header-text;

    &__container {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        min-width: 190px;
        margin: 0 8px;
        @include phone-tablet {
            margin: 0 4px;
        }
        a {
            font-size: 26px;
            font-weight: 400;
            text-transform: uppercase;
            @include phone-tablet {
                font-size: 16px;
                line-height: 16px;
            }
        }
    }

    &__title {
        width: 100%;
        margin-left: calc(64px + 16px);
        a {
            line-height: 28px;
        }
        @media (max-width: 500px) {
            line-height: 20px;
            h1 {
                font-size: 16px;
            }
            h5 {
                font-size: 14px;
            }
        } 
    }
    
    &__tel {
        align-items: flex-end;
        text-align: right;
        @media (max-width: 800px) {
            display: none;
        }
    }
}