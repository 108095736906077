.sro {
    background: $sro-bg;
    &__container {
        display: flex;
        @include phone {
            flex-direction: column;
        }
    }

    &__image {
        background: url('../../assets/img/sro.jpg') center center;
        height: 300px;
        width: 50%;
        @include phone {
            width: 100%;
        }
    }

    &__content {
        padding: 16px;
        width: 50%;
        box-sizing: border-box;
        @include phone {
            width: 100%;
        }
    }
}