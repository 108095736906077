.history {
    background: url('../../assets/img/history-bg.jpg') center;
    background-size: cover;
    color: $history-text;

    &__content {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        margin: -16px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        width: calc(100% / 2 - 32px);
        margin: 16px;
        text-shadow: 1px 1px 2px darkblue;
        @include phone {
            width: 100%;
        }
        span {
            font-family: 'Montserrat', sans-serif;
            font-size: 70px;
            font-weight: 700;
        }
        p {
            max-width: 300px;
            text-shadow: 1px 1px 2px $black, 0 0 1em $darkblue, 0 0 0.4em $black, 0 0 0.2em $darkblue;
        }
    }
}