.property-registration {
    background: $property-registration-bg;
    &__container {
        display: flex;
        @include phone {
            flex-direction: column;
        }
    }

    &__image {
        background: url('../../assets/img/property-registration.jpg') center center;
        height: 300px;
        width: 50%;
        @include phone {
            order: 1;
            width: 100%;
        }
    }

    &__content {
        padding: 16px;
        width: 50%;
        box-sizing: border-box;
        @include phone {
            order: 2;
            width: 100%;
        }
    }
}